
import { OnlineCourses } from "@/api";
import PageTitle from "@/components/common/PageTitle.vue";
import EssayTab from "@/components/onlineCourses/EssayTab.vue";
import GeneralInfo from "@/components/onlineCourses/GeneralInfo.vue";
import LearningMaterials from "@/components/onlineCourses/LearningMaterials.vue";
import PracticalTest from "@/components/onlineCourses/PracticalTab.vue";
import SummativeTest from "@/components/onlineCourses/SummativeTab.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    PageTitle,
    GeneralInfo,
    LearningMaterials,
    PracticalTest,
    SummativeTest,
    EssayTab
  }
})
export default class CreateOnlineCoursePage extends Vue {
  headersTitle = [
    { icon: "mdi-cast-education", header: "Create online course" }
  ];
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];

  tab = 0;
  processing = false;
  courseId: number | null = null;
  formativeMaxGrade = 0;
  summativeMaxGrade = 0;
  qualificationType = "";

  hasFormativeTest = false;
  hasSummativeTest = false;
  hasPracticalTest = false;
  hasEssayTest = false;

  @Watch("isUSQualificationType")
  testsMandatoryChange(val: boolean) {
    if (val) {
      this.hasFormativeTest = true;
      this.hasSummativeTest = true;
      this.hasPracticalTest = false;
      this.hasEssayTest = false;
    } else {
      this.hasFormativeTest = false;
      this.hasSummativeTest = false;
      this.hasPracticalTest = false;
      this.hasEssayTest = false;
    }
  }
  get isUSQualificationType() {
    return this.qualificationType === "US";
  }
  get isQualification() {
    return this.qualificationType === "Qualification";
  }

  get essayTabName() {
    return this.isQualification
      ? "Essay, Case Studys & Reflexive Questions"
      : "Essay Assessment";
  }

  async onSave() {
    if (this.tab === 0) {
      (this.$refs.generalInfo as Vue & {
        onSave: () => void;
      }).onSave();
    } else if (this.tab === 1) {
      (this.$refs.learningMaterial as Vue & {
        save: () => void;
      }).save();
    } else if (this.tab === 2) {
      (this.$refs.summative as Vue & {
        save: () => void;
      }).save();
    } else if (this.tab === 3) {
      (this.$refs.practical as Vue & {
        save: () => void;
      }).save();
    } else if (this.tab === 4) {
      (this.$refs.essay as Vue & {
        save: () => void;
      }).save();
    }
  }
  onDeleteEssay() {
    this.hasEssayTest = false;
    this.tab = 0;
  }
  onDeletePracticalTest() {
    this.hasPracticalTest = false;
    this.tab = 0;
  }
  onDeleteFormativeTest() {
    this.hasFormativeTest = false;
    this.tab = 0;
  }
  onDeleteSummativeTest() {
    this.hasSummativeTest = false;
    this.tab = 0;
  }

  onGeneralInfoSaved({ id }: { id: number }) {
    if (id) {
      this.courseId = id;
    }
    if (this.isUSQualificationType) {
      this.tab++;
    }
  }

  onFormativeSaved() {
    if (this.isUSQualificationType) {
      this.tab++;
    }
    this.loadCourse();
  }
  onSummativeSaved() {
    this.loadCourse();
  }

  async onGoBack() {
    await this.$router.push({ name: "OnlineCoursesList" });
  }

  async loadCourse() {
    if (this.courseId) {
      this.processing = true;

      try {
        const course = await OnlineCourses.info(this.courseId);
        // this.oldCourse = JSON.parse(JSON.stringify(this.course));

        // this.hasFormativeTest = !!this.course.formativeMaxGrade || false;
        // this.hasSummativeTest = !!this.course.summativeMaxGrade || false;
        // this.hasEssayTest = this.course.essayExists || false;
        // this.hasPracticalTest = this.course.practicalExists || false;
        if (course.formativeMaxGrade)
          this.formativeMaxGrade = course.formativeMaxGrade;
        if (course.summativeMaxGrade)
          this.summativeMaxGrade = course.summativeMaxGrade;
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      } finally {
        this.processing = false;
      }
    }
  }
}
