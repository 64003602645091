import { render, staticRenderFns } from "./CreateOnlineCourse.vue?vue&type=template&id=8399aa50&scoped=true&"
import script from "./CreateOnlineCourse.vue?vue&type=script&lang=ts&"
export * from "./CreateOnlineCourse.vue?vue&type=script&lang=ts&"
import style0 from "./CreateOnlineCourse.vue?vue&type=style&index=0&id=8399aa50&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8399aa50",
  null
  
)

export default component.exports